<template>
  <div id="app">
    <nav>
      <router-link to="/">首页</router-link> 
      <router-link to="/tv">TV</router-link>
      <!-- <router-link to="/tv1">TV1</router-link> -->
      <router-link to="/privacy">隐私策略</router-link>
    </nav>
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>
<script>
  export default{
    data(){
      return{
        
      }
    }
  }
</script>
<style>
  html,body{
    margin: 0;
    padding: 0;
    background:#efefef;
  }
  body{
    overflow: hidden;
  }
  nav{
    height: 50px;
    background: #2370dc;
    color:#fff;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
  }
  nav a{
    color:#fff;
    display: block;
    float: left;
    line-height: 50px;
    padding: 0 10px;
    text-decoration: none;
  }
  nav a:hover{
    color:aliceblue;
    background: #4082df;
  }
 
  nav a:active{
    color:aliceblue;
    background: #254d85;
  }
  .container{
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    bottom: 0;
    overflow: auto;
  }
</style>
