<template>
  <el-card class="home">
    <div class="item-head">
      <h2>欢迎访问</h2>
      <h3>主要从事于Asp.net开发。如果你有需要请联系我。</h3>
    </div>
    <el-row>
      <el-col :span="8">
        <div class="item">
          <h2>管理软件</h2>
          <h3>近几年主要研发管理软件，主要有医疗管理软件、健康体检、电子病历、手术麻醉等</h3>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="item">
          <h2>微信公众号</h2>
          <h3>研发自运营公众号（主要用于娱乐、学习）</h3>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="item">
          <h2>App软件</h2>
          <h3>研发Uwp、Android等并在微软商城上架多款应用</h3>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<style scoped>
  .item-head{
    margin:0 5px;
    border:solid 1px #b3b3b3;
    padding:10px;
  }
  .item{
    margin:5px;
    height:200px;
    /* border:solid 1px #b3b3b3; */
    padding:10px;
  }
  h1,h2,h3{
    color:#303133;
  }
  .home{
    margin:10px;
  }
  .header{
    height: 50px;
  }
</style>
<script>


export default {
  name: 'HomeView'
}
</script>
